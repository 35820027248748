@import url('https://use.fontawesome.com/releases/v5.15.4/css/all.css');

body {
  background-image: url('solaCola.jpg');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-attachment: fixed;
  height: 100vh;
  margin: 0;
  animation: background-animation 20s ease infinite;
  overflow: hidden;
}

@keyframes background-animation {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
.logo-wrapper {
  max-width: 100%; /* Ensure the logo wrapper is responsive */
}

.logo-img {
  width: 100%; /* Ensure the logo image fills its container */
  height: auto; /* Maintain aspect ratio */
}
.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  max-width: 70%; 
  margin: 0 auto;
  padding: 20px;
  background-color: rgba(255, 255, 255, 0.8);
  border-radius: 10px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
  overflow-x:hidden;
}



.crypto-address {
  margin-top: 20px;
  background-color: #e61923;
  color: #fff;
  padding: 15px 25px;
  border-radius: 8px;
  text-align: center;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  max-width: 400px;
  width: 80%;
  cursor: pointer;
  transition: transform 0.3s ease;
}

.crypto-address:hover {
  transform: scale(1.05);
}

.crypto-address p {
  font-size: 18px;
  font-weight: bold;
  margin: 0;
  word-wrap: break-word;
}

.crypto-address-label {
  font-size: 14px;
  font-weight: normal;
  margin-bottom: 5px;
}

.button-container {
  display: flex;
  justify-content: center;
  gap: 20px;
  margin-top: 30px;
  flex-wrap: wrap;
}

.link-button {
  text-decoration: none;
}

button {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  padding: 12px 24px;
  font-size: 18px;
  font-weight: bold;
  background-color: #e61923;
  color: #fff;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, background-color 0.3s ease, box-shadow 0.3s ease;
}

button:hover {
  transform: scale(1.05);
  background-color: #b01318;
  box-shadow: 0 8px 12px rgba(0, 0, 0, 0.2);
}

button:active {
  background-color: #8b0f13;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  transform: translateY(2px);
}

/* Animation styles */
.logo-enter {
  opacity: 0;
  transform: translateY(-50px);
}

.logo-enter-active {
  opacity: 1;
  transform: translateY(0);
  transition: opacity 1000ms, transform 1000ms;
}

.logo-exit {
  opacity: 1;
}

.logo-exit-active {
  opacity: 0;
  transform: translateY(-50px);
  transition: opacity 1000ms, transform 1000ms;
}


.buttons-enter {
  opacity: 0;
  transform: translateY(50px);
}

.buttons-enter-active {
  opacity: 1;
  transform: translateY(0);
  transition: opacity 1000ms, transform 1000ms;
}

.buttons-exit {
  opacity: 1;
}

.buttons-exit-active {
  opacity: 0;
  transform: translateY(50px);
  transition: opacity 1000ms, transform 1000ms;
}

@media screen and (max-width: 768px) {
  
  .logo-img {
    max-width: 100%;
    max-height:200px; 
  }
  .container {
    padding: 10px;
    border-radius: 0;
    box-shadow: none;
  }

  .button-container {
    flex-direction: column;
    align-items: center;
    gap: 10px;
  }

  button {
    font-size: 16px;
    padding: 10px 20px;
  }
  .crypto-address p {
    font-size: 14px; 
  }

 
  .crypto-address {
    margin-top: 10px; 
  }

  
  .crypto-address-label {
    font-size: 16px; 
  }

}
